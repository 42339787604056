import React from 'react';

import Section from '../../../HOC/Section';

const Advantage = () => {
  return (
    <Section id='advantage'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            Our Team
          </h3>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='advantage-box d-flex'>
                <div className='advantage-icon mr-4'>
                  <i className='fa fa-check-circle' />
                </div>
                <div className='advantage-body'>
                  <h5 className='advantage-title'>Professionalism</h5>
                  <p className='advantage-description'>
                    Our team of certified public accountants will bring their years of experience and 
                    expertise to provide you with professional advice and advice on tax, consulting, 
                    compliance and other issues. 
                    <br/> <br/> 
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='advantage-box d-flex'>
                <div className='advantage-icon mr-4'>
                  <i className='fa fa-check-circle' />
                </div>
                <div className='advantage-body'>
                  <h5 className='advantage-title'>Long History Expertise </h5>
                  <p className='advantage-description'>
                    CA has a long history of expertise in tax planning, tax benchmarking, 
                    corporate financial control, asset and capital share management and planning. 
                    We will help you and your business structure or business restructuring formulate
                  </p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Advantage;
import React, { useState, useEffect } from 'react';

import Link from '../Link/Link';

const NavigationBar = () => {
  const [navClass, setNavClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 300) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);

  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className='container'>
        
        <div>
          
          <ul className='navbar-nav ml-auto'>
          <img src={require('../../../assets/img/logo/logo@2x.png')} className="nav-logo" alt=""/>
            <li className='nav-item ml-5'>
              <Link target='home' offset={-120} classes='nav-link'>
                Home
              </Link>
            </li>
           
            <li className='nav-item'>
              <Link target='services' classes='nav-link'>
                Service
              </Link>
            </li>
            <li className='nav-item'>
              <Link target='contact' classes='nav-link'>
                Contact
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
      
    </nav>
  );
};

export default NavigationBar;

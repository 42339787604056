import React from 'react';

import Section from '../../../HOC/Section';

const contact = () => {
  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            Accounting Service
          </h3>
          <h4 className='' style={{textAlign: "left", paddingLeft:"35%"}}>
            <li>Bookkeeping</li>
            <li>Management Accounting</li>
            <li>Taxation</li>
            <li>Business Advisory</li>
            <li>Financial Accounting</li>
            <li>Tax Planning</li>
            <li>Cash Flow Management</li>
            <li>SMSF</li>
            <li>Entity Structures</li>
          </h4>
        </div>
      </div>
    </Section>
  );
};

export default contact;

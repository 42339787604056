import React, { Fragment } from 'react';

import Home from './Home/Home';
import Service from './Service/Service';
import Contact from './Contact/Contact';
import Advantage from './Advantage/Advantage';

const sections = () => {
  return (
    <Fragment>
      <Home />
      <Advantage/>
      <Service />
      <Contact/>
    </Fragment>

  );
};

export default sections;

import React from 'react';

const footer = () => {
  return (
    <footer className='' id="contact">
      <div className='container text-dark pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title text-light'>
              <h6>About Us</h6>
            </div>
            <div className='footer-content text-light'>
              <p>
                <small className='text-lighted'>
                We are committed to helping you clear the fog of your tax obligations and free up valuable time to focus on the operation and growth of your business.
                </small>
              </p>
            </div>
          </div>

          <div className='col-sm-6 col-md-6 col-lg-3 mb-5 '>
          </div>
          
         
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5 text-light'>
            <div className='footer-title'>
              <h6>Contact Us</h6>
            </div>
            <div className='footer-content'>
              <p className='text-light'>
                <small>Phone : (+61) 0478 860 866</small>
              </p>
              <p className='text-light'>
                <small>Address : 6 Karana Ct, Croydon VIC 3136</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© Copyright By CACOUNSULTING</small>
      </div>
    </footer>
  );
};

export default footer;

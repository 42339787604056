import React from 'react';

import Section from '../../../HOC/Section';

import serviceImage1 from '../../../assets/img/ac1.jpg';
import serviceImage2 from '../../../assets/img/ac2.jpg';
import serviceImage3 from '../../../assets/img/SMSF.jpg';
import serviceImage4 from '../../../assets/img/Time_management.jpeg';
import serviceImage5 from '../../../assets/img/Education _Fund.jpeg';

const Service = () => {
  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            Our Service
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          We are committed to helping you clear the fog of your tax obligations and free up valuable time to focus on the operation and growth of your business.
          Regardless of the size of your business, we offer professional advice on what you need to achieve your goals. 
          Whether it is implementing business strategy, increasing cash flow, improving profits, or providing compliance advice and business growth, we are always ready to provide you with the best long-term service.
          </h6>

        </div>
        <div className='section-content'>
          <div class="container">
            <img class="container__image1" src={serviceImage1}/>
            <div class="container__text1">
              <h2 className='card-title pt-3 pb-2'>Taxation Service</h2>
              <p className='card-text'>
                We provide specialist taxation advice and return preparation for companies, trusts and individuals.
              </p>
            </div>
          </div>
          <div class="container">
            <div class="container__rtext">
              <h2 className='card-title pt-3 pb-2'>Bookkeeping &amp; Management</h2>
              <p className='card-text'>
                From financial statement preparation, budgeting, cash flow forecasting and bookkeeping services.
              </p>
            </div>
            <img class="container__rimage" src={serviceImage2}/>
          </div>
        </div>

        <div className='section-content'>
          <div class="container">
            <img class="container__image" src={serviceImage3}/>
            <div class="container__text">
              <h2 className='card-title pt-3 pb-2'>SMSF</h2>
              <p className='card-text'>
                Your Superannuation fund balance is an important aspect of your retirement planning. 
                MEX assists SMSF with comprehensive, up-to-date advice.
              </p>
            </div>
          </div>
          <div class="container">
            <div class="container__rtext">
              <h2 className='card-title pt-3 pb-2'>Time management</h2>
              <p className='card-text'>
              Our professional services will save you valuable time and resources so you can focus on your 
              business and other important matters.
              </p>
            </div>
            <img class="container__rimage" src={serviceImage4}/>
          </div>
        </div>
        <div className='section-content'>
          <div class="container">
            <img class="container__image" src={serviceImage5}/>
            <div class="container__text">
              <h2 className='card-title pt-3 pb-2'>Education fund</h2>
              <p className='card-text'>
                A reasonable and effective combination of education tax credits (ETB) and 
                investment strategies to provide better educational resources for your children's future.
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </Section>
  );
};

export default Service;


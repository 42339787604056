import React from 'react';

import NavigationBar from '../NavigationBar/NavigationBar';
import TopBar from '../TopBar/TopBar';

const header = () => {
  return (
    <div className='header'>
      <TopBar />
      <NavigationBar />
    </div>
  );
};

export default header;

import React from 'react';

import Section from '../../../HOC/Section';
import Link from '../../FixedSections/Link/Link';


import backgroundImage from '../../../assets/img/a2.jpg';
const HomeTop = () => {
  return (
    <Section id='home'>
      <div>
        <div className='home-content p-5' style={{ float: 'left', width: "50%"}}>

          <div className='header container text-left home-text'>
            <h1 className='title'>
              CA CONSULTING GROUP 
            </h1>
          </div>
          
        </div>
        <div className='home-content' style={{backgroundImage: `url(${backgroundImage})`}}>

        </div>
      </div>
      
    </Section>
  );
};

export default HomeTop;
